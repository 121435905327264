import React from 'react';
import { graphql } from 'gatsby';
import { Container, Box, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';

import SEO from '../components/seo';
import { PageInfo, ReleasesQueryQuery } from '../../types/graphql-types';
import RichContent from '../components/text/rich-content';
import RequireAuthDownloadButton from '../components/button/require-auth--download-button';
import Link from '../components/Link';
import GridContainer from '../components/Grid/GridContainer';
import GridLeft from '../components/Grid/GridLeft';
import LineBreak from '../components/LineBreak';
import CenterContainer from '../components/Pagination/CenterContainer';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Pagination from '@material-ui/lab/Pagination';

export interface ReleasesQuery {
  pageContext: PageInfo;
  data: ReleasesQueryQuery;
}

const Releases = ({ pageContext, data }: ReleasesQuery): JSX.Element => {
  const { allContentfulBimxplorerSetup } = data;

  return (
    <>
      <SEO title="Releases" />

      <Container maxWidth="md">
        <Box mt={5}>
          <GridContainer container spacing={4}>
            {allContentfulBimxplorerSetup.edges.map(({ node }, index) => (
              <React.Fragment key={node.slug || index}>
                <GridLeft item xs={4}>
                  <RichContent>v{node.version}</RichContent>
                </GridLeft>
                <Grid item xs={8}>
                  <Link href={`/releases/tag/${node.slug}`}>v{node.version}</Link>
                  <Typography
                    component="h3"
                    variant="h5"
                    align="left"
                    color="textPrimary"
                    gutterBottom
                  >
                    {node?.title}
                  </Typography>
                  <Typography variant="h6" align="left" color="textSecondary" component="p">
                    {node?.publishDate}
                  </Typography>
                  <RichContent
                    dangerouslySetInnerHTML={{
                      __html: node?.releaseNotes?.childMarkdownRemark?.html || '',
                    }}
                  />
                  <Typography
                    component="h2"
                    variant="h5"
                    align="left"
                    color="textPrimary"
                    gutterBottom
                  >
                    Attachments
                  </Typography>
                  <Typography>
                    {node?.setupFile?.file?.url && (
                      <RequireAuthDownloadButton
                        href={(node?.setupFile?.file?.url as string) || undefined}
                        color="primary"
                      >
                        <>
                          <GetAppIcon />
                          Download ({node?.version})
                        </>
                      </RequireAuthDownloadButton>
                    )}
                  </Typography>
                  <Typography>
                    {node?.documentation?.file?.url && (
                      <Link
                        href={(node?.documentation?.file?.url as string) || undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {node?.documentation?.file?.url ? 'Documentation' : 'See previous'}
                      </Link>
                    )}
                  </Typography>
                  <LineBreak />
                </Grid>
              </React.Fragment>
            ))}
          </GridContainer>
        </Box>

        {!!pageContext.pageCount &&
          pageContext.currentPage !== null &&
          pageContext.currentPage !== undefined &&
          pageContext.pageCount > 1 && (
            <>
              <CenterContainer>
                <Pagination
                  page={pageContext.currentPage}
                  count={pageContext.pageCount}
                  renderItem={(item): JSX.Element => (
                    <PaginationItem
                      component={Link}
                      to={`/releases${item.page === 1 ? '' : `/${item.page}`}`}
                      {...item}
                    />
                  )}
                />
              </CenterContainer>
            </>
          )}
      </Container>
    </>
  );
};

export default Releases;

export const blogListQuery = graphql`
  query ReleasesQuery($skip: Int!, $limit: Int!) {
    allContentfulBimxplorerSetup(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          version
          slug
          releaseNotes {
            childMarkdownRemark {
              html
            }
          }
          setupFile {
            file {
              url
            }
          }
          documentation {
            file {
              url
            }
          }
          title
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`;
